@import url('https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@1,700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
*{
  box-sizing: border-box;
}
body{
  height: 100vh;
  width: 100vw;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}
.header-top{
  font-weight: 400;
    color: #212529;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 0;
}
.accordion{
  height: auto;
}
.titre{
  font-size: 38px;
  font-family: "Source Sans 3", sans-serif;
}
.header1{
  padding: 10px 20px;
  display: none;
}

.HeaderTopPart1{
  width: 70vw;
}
.HeaderTopPart2{
  width: 40vw;
}
.NavBar{
  background-color: black;
  color: white;
  box-shadow: 0 0 25px rgba(0,0,0,0.2);
   position: sticky;  
  width: 100vw;
  z-index: 1000;
  top: 8vh;  
}
.NavBar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.menu-icon {
  display: block; 
  background-color: #FFCB05;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}
.VerticalMenu {
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  top: 14vh;
  width: 100vw;
}

.VerticalMenu li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.VerticalMenu li:hover {
  background-color: #ddd;
}

.VerticalMenu li a {
  text-decoration: none;
  color: black;
}
.nav-items {
  display: none; 
}

.navLogin{
  display: none;
}


nav{
  display: flex;
  justify-content: end;
}
.firstSection{
  display: flex;
  /* padding-top: 15vh; */
  height: 200vh;
  flex-direction: column;
}

article{
  width: 90vw;
  padding-left: 5vw;
}
aside{
  width: 40vw;
  padding-left: 5vw;
  padding-top: 20px;
}

article h1{
  font-size: 40px;
  font-weight: 700;
  color: #212529;
  font-family: "Source Sans 3", sans-serif;
}
.secondTitle{
  font-size: 25px; 
  font-weight: 100;
  color: #212529;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: "Source Sans 3", sans-serif;
}
article p {
  font-size: 16px;
  padding-left: 10px; 
  font-weight: 100;
  color: #212529;
  text-transform: uppercase;
  font-family: "Source Sans 3", sans-serif;
}
article ul li{
  list-style-type: none;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Source Sans 3", sans-serif;
}
.btnSection{
  background-color: #FFCB05;
  color: #000000;
  border-bottom: 6px solid #000;
  border-top:none;
  border-right:none;
  border-left:none;
  border-radius: 5px ;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.75);
  padding: 8px;
  text-decoration: none;
  text-transform: none;
  color: #181818;
  margin-top: 10px;
  width: 30vw;
  font-family: "Source Sans 3", sans-serif;
}
.btnSecondSection{
  border-bottom: 6px solid #FFCB05;
  border-top:none;
  border-right:none;
  border-left:none;
  border-radius: 5px ;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.75);
  background-color: #000000;
  padding: 8px;
  color: white;
  margin-right: 10px;
  text-decoration: none;
  text-transform: none;
  width: 35vw;
  margin-top: 20px;

}
.btnSecondSectionTwo{
  border-bottom: 6px solid #FFCB05;
  border-top:none;
  border-right:none;
  border-left:none;
  border-radius: 5px ;
  font-size: 18px;
  font-weight: 700;
  /* text-transform: uppercase; */
  box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.75);
  background-color: #000000;
  padding: 8px;
  color: white;
  margin-right: 10px;
  text-decoration: none;
  text-transform: none;
  width: 35vw;
  margin-top: 20px;
  cursor: pointer;

}
.btnSecondSectionTwo:hover{
  color: white;
  text-decoration: none;
}
.btnSecondSection:hover{
  color: white;
  text-decoration: none;
}
.btnSection:hover{
  color:black;
  text-decoration: none;
}
.secondImage{
  width: 30vw;
  height: 70vh;
  padding: 20px 0;

}
.SectionSecondImage{
  width: 40vw;
  height: 70vh;
}
.cardSection{
  width: 55vw;
  height: 32vh;
  text-align: center;
  box-shadow: 0 15px 25px rgba(28, 28, 28, 0.15);
  z-index: 2;
  margin: 15px auto;
  padding: 15px;
}
.ForthSectiomImage {
  width: 55vw; 
  height: 135vh; 
  padding-top: 20vh;
}
.ForthAside{
  width: 50vw;
}
.lastImage{
  width: 52vw;
  height: 40vh;
}
.fiveImage{
  width: auto;
  height: 22vh;
}
.lastImage iframe{
  width: 42vw;
  height: 50vh;
}
.lastImage img{
  width: 42vw;
  height: 50vh;
}
.lastPart{
  margin-top: 10vh;
}
.lastImageCard p{
  font-size: 14px;
}
.lastCardImage {
  width: 40vw;
  height: 40vh;
}
footer{
  height: auto;
  background-color: #000;
  color: white;
  flex-shrink: 0;
  width: 100vw;
}
.scrollable-content {
  max-height: calc(100vh - 100px); 
  overflow-y: auto;
}
.imageNews{
  width: 50vw;
  height: 50vh;
}
.registration-success{
  border: 2px solid "#000000";
  margin: 10px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  margin-top: 20px;
  padding-left: 20px;
}
.shopSection{
  height: auto;
  padding-bottom: 20px;
}
.grid-container .card{
  width: 22vw;
}

.formSelect{
  width: 20vw;
  border: 0;
  padding-top: 10px;
}
.btn{
  width: 20vw;
  border: "1px solid #FFCB05";
  color: "#FFCB05";
  text-decoration: none;
  text-transform: none;
}

.quickSammary{
  width: 30vw;
}
.firstSecNews{
  width: 100vw;
}
.card-img-top{
  height: 50vh;
}
.newsSection{
  height: 200vh;
  flex-direction: row;
  width: 100vw;
}
.imgNewsSection{
  width: 50vw;
  height: 50vh;
  padding: 3vh 0;
}
.firstSectionNews{
  width: 100vw;
}
.newsSection article{
  width: 50vw;
}
.imgNewsSepecial{
  width: 50vw;
  height: 95vh;
}
.popup-overlay {
  position: fixed; /* Full-screen layout */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1050; /* Make sure it's above other content */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
  max-width: 500px; /* Set a max-width for larger screens */
  width: 90%; /* Use most of the screen size on smaller screens */
  text-align: center; /* Center the text */
}

.popup-content p {
  margin: 0 0 20px; /* Add some spacing below the message */
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff; /* Bootstrap primary blue */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-content button:hover {
  background-color: #0056b3; /* A slightly darker blue */
}

@media (min-width: 770px) {
  .newsArticle{
    width: 50vw;
    padding-left: 0;
  }
  aside img{
    width: 70vw;
    height: 85vh;
  }
  .printInstruction{
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 100vw;
    padding-left: 5vw;
  }
  .accordion-item{
    width: 80vw;
  }
  .printInstruction .btnSection{
    background-color: #FFCB05;
    width: 2vw;
    cursor: pointer;
    border-radius: 0;
  }
  .header1 {
      display: flex ;
      justify-content: center;
  }
  /* .NavBar{
    background-color: black;
    color: white;
    box-shadow: 0 0 25px rgba(0,0,0,0.2); */
    /* position: fixed;  */
    /* top: 8vh; 
    width: 100vw;
    z-index: 1000; 
  } */
   /* ::-webkit-scrollbar {
    width: 12px;
  } */
   :state(webkit-scrollbar) {
    width: 12px;
  }   
  
  .navLogin{
    display: flex;
  }
  .nav-items {
    display: block; 
  }
  .menu-icon {
    display: none; 
  }
  nav{
    display: none;
  }
  .VerticalMenu {
    display: none;
  }
  .firstSection{
    flex-direction: row;
    height: 90vh;
    width: 100vw;
  }
  
  article{
    width: 45vw;
    padding-left: 5vw;
    height: auto;
  }
  aside{
    width: 40vw;
    padding-left: 5vw;
  }
  aside img{
    width: 40vw;
    height: 80vh;
  }
  article h1{
    font-size: 28px;
    font-weight: 700;
    color: '#212529';
  }
  .secondSection {
    display: flex;
    height: 120vh;
    padding: 5vh 0;
    flex-direction: row;
  }
  .asideSecondSection{
    margin-bottom: 20vh;
  }
  .thirdSection article {
    width: 45vw;
  }
  .thirdSection aside{
    align-self: flex-end;
  }
  .thirdSection article span{
    width: 35vw;
  }
  .thirdSection{
    display: flex;
    height: 80vh;
    padding: 5vh 0;
    flex-direction: row;
  }
  .forthSection {
    display: flex;
    height: 120vh;
    padding: 5vh 0;
    flex-direction: row;
  }
  .forthSection .cardSection {
    width: 52vw; 
    margin: 15px; 
    height: 35vh;
  }
  .forthSection .cardSection p{
    text-transform: lowercase;
  }
  .fiveSection {
    display: flex;
    height: 60vh;
    padding: 5vh 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .fiveSection article{
    height: 50vh;
  }
  .lastArticle{
    width: 50vw;
  }
  .scrollable-content {
    max-height: calc(100vh - 100px); 
    overflow-y: auto;
  }
  /* .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  } */
  .fiveSectionDiv {
    flex-direction: row;
  }
  .fiveImageCard{
    width: 22vw;
    margin-left: 0;
    padding-right: 10px;
  }
  .fiveImageCard p{
    padding-left: 0;
    text-transform: lowercase;
  }
  .container-shop {
    flex-direction: column;
    align-items: center;
}
.container-shop .form-control {
    width: 80%; /* Ajuster selon besoin */
}
.container-shop button {
    margin: 5px 0; /* Ajoute un peu d'espace entre les boutons */
    width: 80%; /* Ajuster selon besoin */
}
.shopSection{
  padding-left: 10px;
}
.partCateg{
  padding-left: 10px;
}
.partShopPage{
  padding-right: 3vw;
}
.dropdown button{
  border:0;
  width : 10vw
}
.btnCategory{
  display: none;
}
.firstPrint div{
  flex-direction: column;
  /* width: 30vw; */
  justify-content: space-between;
}
.firstPrintDiv{
  width: 60vw;
}
.quickSammary{
  width: 30vw;
}
.btnToCart{
  width: 20vw;
}
.tradeShowSection{
  width: 100vw;
}
.footer {
  padding-bottom: 25px;
}
.lastSection .btnSecondSection{
  margin-bottom: 10px;
}
} 


@media (max-width: 768px) {
  .imgNewsSepecial{
    width: 50vw;
    height: 75vh;
  }
  .newsSection article{
    width: 90vw;
  }
  .newsSection article{
    width: 90vw;
  }
  .imgNewsSection{
    width: 80vw;
  }
  .imgNewsSection img{
    width: 70vw;
  }
  .accordion-item{
    width: 85vw;
  }
  .imageNews{
    width: 80vw;
    height: 35vh;
  }
  .tradeShowSection{
    width: 110vw;
  }
  .btnToCart{
    width: 40vw;
  }
  .card-img-top{
    height: 60vh;
  }
  .newsArticle{
    width: 100vw;
  }
  .firstSecNews{
    width: 110vw;
  }
  .VerticalMenu {
    right: 0;
    top: 16vh;
    width: 110vw;
  }
  .printInstruction .btnSection{
    background-color: #FFCB05;
    width: 7vw;
    cursor: pointer;
    border-radius: 0;
  }
  .quickSammary{
    width: 90vw;
  }
  .NavBar{
    width: 100vw;
  }
  .printInstruction{
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 100vw;
    padding-left: 5vw;
  }
  .firstPrint{
    flex-direction: column;
    width: 90vw;
  }
  .firstSection{
    height: auto;
    width: 110vw 
  }
  .firstprint{
    width: 110vw;
  }
  .partCateg{
    display: none;
    padding: 0;
    margin: 0;
  }
  .btnCategory{
    background-color: white;
    border: 1px solid #000;
  }
  .dropdown button{
    border:0;
    width : 35vw
  }
  .countProducts{
    display: none;
  }
  .navbarCategory{
    padding-top: 3vh;
  }
  .firstSec{
    width: 110vw;
  }
.partShopPage{
  padding-right: 5vw;
}

.btnSecondSectionTwo{
  font-size: 10px;
}
  .firstSection article{
    width: 100vw;
  }
  .firstSection article h1{
    font-size: 24px;
  }
  .firstSection article > span{
    font-size: 18px;
  }
  .firstSection article p{
    font-size: 12px;
    padding-top: 10px;
    padding-left: 0;
  }
  .firstSection article > ul{
    font-size: 12px;
    padding-top: 10px;
    padding-left: 0;
  }
  .firstSection aside {
    padding-bottom: 20px;
  }
  .firstSection aside img{
    width: 400px;
  }
  .SectionSecondImage {
    width: 400px;
    height: auto;
  }
  .secondSection  article h1 {
    font-size: 22px;
    padding-top: 4vh;
  }
  .secondSection aside {
    width: 90vw;
  }
  .secondSection  article span {
    font-size: 18px;
  }
  .secondSection  article p {
    font-size: 12px;
    padding-top: 10px;
    padding-left: 0;
  }
  .thirdSection{
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .thirdSection article h1{
    font-size: 24px;
    padding-top: 15px;
  }
  .thirdSection article span {
    font-size: 18px;
  }
  .thirdSection article p {
    font-size: 12px;
    padding-top: 10px;
    padding-left: 0;
  }
   .thirdSection article  {
    padding-left: 0;
  }
  .thirdSection .btnSecondSection{
   width: 40vw;
   font-size: 12px;
  }
  .thirdSection .btnSection{
   width: 45vw;
   font-size: 12px;
  }
  .thirdSection aside{
    align-self: flex-start;
    width: auto;
  }
  .thirdSection .secondImage {
    height: auto;
    width: 350px;
  }
  .forthSection {
    flex-direction: column; 
    align-items: center;
    height: auto;
    padding: 20px 0;
    padding-top: 0;
  }

  .forthSection aside,
  .forthSection article {
    width: 100%; 
    padding-top: 0;
  }

  .forthSection button.btnSecondSection {
    padding: 10px;
    font-size: 16px; 
    display: block; 
    width: 45vw; 
  }
  .forthSection article p{
    font-size: 12px; 
    padding-top: 10px;
  }
  
  .forthSection .cardSection {
    width: 90vw; 
    /* margin: 15px;  */
    height: auto;
  }
  .cardSection{
    margin: 15px 0;
  }
  .globalcardSection{
    flex-direction: column;
  }
  /* .forthSection article h1{
    font-size: 24px;
  }
  .forthSection article span {
    font-size: 18px;
  }
  .forthSection .cardSection {
    width: auto;
    margin: 5px;
  }
  .forthSection .cardSection b{
    font-size: 12px;
  }
  .forthSection .cardSection p{
    font-size: 10px;
  }
  .forthSection button.btnSecondSection {
    width: auto;
    margin-top: 15px;
    
    
  }
  .forthSection .btnSecondSection{
    margin-left: 35px;
    font-size: 12px;
  }*/
  .ForthSectiomImage {
    width: 60vw; 
    height: auto; 
    padding-top: 0vh;
  } 
  .fiveSection{
    height: auto;
  }
  .fiveSection article h1{
    font-size: 24px;
    padding-top: 20px;
  }
  .fiveSection article span {
    font-size: 14px;
  }
  .fiveSection article p {
    font-size: 12px;
    padding-left: 0;
    padding-top: 10px;
  }
  .fiveSection div {
    width: 100vw;
    padding: 0 ;
    margin-bottom: 0;
  }
  .fiveSection article > div {
    flex-direction: column;
  }
  .fiveSection .lastImageCard{
    width: 45vw;
    height: auto;
    margin-bottom: 0;
  }
  .fiveSection .btnSecondSection {
    width: 70vw;
    margin-top: 15px;
    font-size: 12px;
  }
  .lastImageCard img{
    width: 40vw;
    height: 30vh;
    padding: 0;
  }
  .lastImage iframe{
    width: 60vw;
    height: 50vh;
  }
  .fiveSection div b{
    font-size: 12px;
  }
  .fiveSection div p{
    font-size: 10px;
  }
  .lastSection{
    width: 100vw;
  }
  .lastSection article{
    width: 60vw;
  }
  .lastSection article h1{
    font-size: 20px;
    margin-top: 35px;
  }
  .lastSection article p{
    font-size: 14px;
  }
  .lastSection article img{
    width: 60vw;
  }
  .lastArticle {
    padding: 0;
}
  .lastSection article button{
    width: 90vw;
    margin: 0;
  }
  .lastSection .btnSecondSection{
    margin: 0;
    width: 30vw;
  }
  footer {
    padding: 20px; 
    height: auto;
    width: 100vw;
  }
  .lastFooter{
    margin-left: 10vw;
    margin-top: 1vh;
  }
.footer .d-flex {
    flex-direction: column; 
    align-items: center; 
    /* margin-left: 8vw; */
}

.footer .text-center {
    margin: 10px 0; 
}
.footer .lastFooter {
  margin-left:25px;
}

.footer img {
    width: 120px; 
}

.footer p, .footer b, .footer a {
    font-size: 14px; 
}

.footer .mx-4 {
    margin-left: 0 !important;
    margin-right: 0 !important; 
}
.container-shop {
  flex-direction: column;
  align-items: center;
}
.container-shop .form-control {
  width: 90%; 
}
.container-shop button {
  margin: 5px 0; 
  width: 90%; 
}
.grid-container {
  grid-template-columns: 1fr; 
}

.grid-container .card{
  width: 90vw;
}
.formSelect{
  width: 45vw;
  border: 0;
  padding-top: 10px;
}

.btn{
  width: "42vw";
  border: "1px solid #FFCB05";
  color: "#FFCB05";
}
.lastSection .btnSecondSection{
  width: 60vw;
  font-size: 18px;
  margin-bottom: 10px;
}

}
@media (max-width: 375px) {
  .NavBar{
    width: 100vw;
  }
  .lastSection .btnSecondSection{
    margin: 0;
    width: 50vw;
  }
  .firstSection aside img {
    width: 300px;
  }
  .SectionSecondImage {
    width: 300px; 
  }
  .thirdSection .secondImage {
    width: 300px;
  }
  .ForthSectiomImage {
    width: 350px;
  }
  .lastSection article h1 {
    margin-top: 0;
  }
  .lastSection .btnSecondSection{
    width: 90vw;
    font-size: 14px;
  margin-bottom: 10px;
  }
}